(function () {
    'use strict';

    angular.module('aerosApp')
        .controller('ToolTypeController', ToolTypeController);

    ToolTypeController.$inject = ['$stateParams', 'toolsDefinition', 'routesConstant'];

    function ToolTypeController($stateParams, toolsDefinition, routesConstant) {
        var vm = this;

        vm.toolType = $stateParams.toolType;
        vm.toolsDefinition = toolsDefinition[$stateParams.toolType];
        vm.toolGroups = getToolGroups(toolsDefinition);
        vm.header = vm.toolsDefinition && vm.toolsDefinition.name ? vm.toolsDefinition.name + 's' : 'Tools';
        vm.CUSTOMER = routesConstant.CUSTOMER;

        function getToolGroups(def) {
            return def[vm.toolType] ? def[vm.toolType].groups : [];
        }
    }
})();
